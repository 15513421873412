var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.trust ? _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_vm.trust ? _c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("hibah-draft")))]), _c('p', [_vm._v(" " + _vm._s(_vm.$t("check-hibah-click-button")) + " "), _c('strong', [_vm._v("\"" + _vm._s(_vm.$t("view-hibah-draft")) + "\"")]), _vm._v(". " + _vm._s(_vm.$t("req-confirm-hibah")) + " ")]), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.viewDraff
    }
  }, [_vm._v(_vm._s(_vm.$t("view-hibah-draft")))])], 1), _c('hr', {
    staticClass: "primary"
  }), !_vm.btnSetujuOTP && !_vm.btnSetujuSign && _vm.trust ? _c('div', {
    staticClass: "d-flex justify-content-around mt-3"
  }, [_c('b-button', {
    staticClass: "btn btn-danger",
    on: {
      "click": _vm.rejectDraff
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("i-disagree")) + " ")]), _c('b-button', {
    staticClass: "btn btn-success",
    on: {
      "click": _vm.acceptDraff
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("i-agree")) + " ")])], 1) : _vm._e(), _vm.btnSetujuOTP && !_vm.btnSetujuSign ? _c('div', [_c('div', {
    staticClass: "d-flex justify-content-center m-4"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('v-otp-input', {
    ref: "otpInput",
    attrs: {
      "input-classes": "otp-input",
      "separator": "-",
      "num-inputs": 6,
      "should-auto-focus": true,
      "is-input-num": true
    },
    on: {
      "on-change": _vm.handleOnChange,
      "on-complete": _vm.handleOnComplete
    }
  })], 1)]), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('b-btn', {
    staticClass: "btn-sm btn-warning m-2",
    on: {
      "click": _vm.requestOtp
    }
  }, [_vm._v(_vm._s(_vm.btnotptext))])], 1), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_vm.otpSent ? _c('span', [_c('small', [_vm._v(_vm._s(_vm.$t("otp-sent")))])]) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "row mt-3"
  }, [_vm.trust.status == 3 && _vm.btnSetujuSign || _vm.btnSetujuSign && _vm.amendmentId ? _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "card",
    staticStyle: {
      "width": "400px",
      "display": "inline-block"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "relative",
      "height": "300px",
      "width": "100%"
    }
  }, [_c('VueSignaturePad', {
    ref: "signaturePad",
    staticStyle: {
      "position": "absolute",
      "z-index": "1"
    },
    attrs: {
      "height": "300px",
      "width": "500px",
      "options": {
        onBegin: function () {
          _vm.$refs.signaturePad.resizeCanvas();
        }
      }
    }
  }), _c('label', {
    staticStyle: {
      "z-index": "0",
      "position": "absolute",
      "right": "0px",
      "left": "0px",
      "top": "45%",
      "color": "#b6b6b4"
    }
  }, [_vm._v(" Tandatangan disini ")]), _c('a', {
    staticClass: "btn btn-primary",
    staticStyle: {
      "position": "absolute",
      "right": "5px",
      "top": "5px",
      "z-index": "2"
    },
    on: {
      "click": _vm.undo
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/rubber.svg",
      "height": "20",
      "alt": ""
    }
  })])], 1)])]) : _vm._e()]), _vm.trust.status == 3 && _vm.btnSetujuSign || _vm.btnSetujuSign && _vm.amendmentId ? _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.selesai
    }
  }, [_vm._v(_vm._s(_vm.$t("done")))])], 1) : _vm._e()])]) : _vm._e()]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }